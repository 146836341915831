<script>
export default {
	name: 'NotFound'
}
</script>
<template>
	<div class="not-found">
		<div class="not-found__content">
			<h1 class="not-found__number">404</h1>
			<h1 class="not-found__title">{{ $t('commonMsg.errorPageTitle') }}</h1>
			<p class="not-found__info">{{ $t('commonMsg.errorPageInfo') }}</p>
		</div>
	</div>
</template>
<style lang="scss">
	.not-found {
		display: flex;
		justify-content: center;
		font-family: 'Noto Sans JP', 'Roboto', sans-serif;
		align-items: center;
		height: 100vh;
		&__content {
			padding: 0 1rem 0 1rem;
			text-align: center;
		}
		&__number {
			font-size: 2.5rem;
		}
		&__title {
			font-size: 1.3rem;
			font-weight: 500;
			padding-top: 0.5rem;
		}
		&__info {
			font-size: 1rem;
			padding-top: 0.5rem;
		}
	}
</style>
